import { PhosphorIcon } from "~/widgets/icons";
import { zIndexes } from "~/z_indexes";
import { Show } from "solid-js";
import { isCloseBtnEnabledFor } from "~/utils/common";
import { useSearchParams } from "@solidjs/router";
import { HUBBLE_CLIENT_ID } from "~/data/config";

export function SdkTopRightCloseButton() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Show
      when={isCloseBtnEnabledFor(searchParams.clientId ?? HUBBLE_CLIENT_ID)}
    >
      <div
        class="absolute right-[10px] top-[10px] flex h-10 w-10 cursor-pointer items-center  justify-center rounded-[50%] border-[1px] border-solid border-[#777] bg-white text-[#777] hover:border-[#000] hover:text-[#000] lg:right-[20px] lg:top-[20px]"
        style={{ "z-index": zIndexes.header }}
        onClick={(event) => {
          event.stopPropagation();
          window.parent.postMessage(
            {
              type: "close",
            },
            "*"
          );
        }}
      >
        <PhosphorIcon
          name="x"
          size="bold"
          fontSize={20}
          class="text-[#777] hover:text-[#000]"
        />
      </div>
    </Show>
  );
}
